body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

button,
input {
  border: none;
  box-shadow: none;
  outline: none;
}

div,
section,
button,
input {
  box-sizing: border-box;
}

* {
  -webkit-tap-highlight-color: transparent !important;
}
